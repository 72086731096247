import React, {useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {HomeToolBar} from "../components/home-toolbar";
import {prices} from "../components/price-plans";
import PricingCard from "../components/pricing-card";
import {styled} from "@mui/material/styles";
import useIsMobile from "../components/mobile";


export default function RewardSpacePricing() {
	const [activeIndex, setActiveIndex] = useState(0);
	const isMobile = useIsMobile();

	const handleNext = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === prices.length - 1 ? 0 : prevIndex + 1
		);
	};

	const handlePrevious = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === 0 ? prices.length - 1 : prevIndex - 1
		);
	};

	const getTier = (prices, indent) => {
		if (prices.length === 0) return null;
		const normalizedIndent = (activeIndex + indent) % prices.length;
		const index =
			normalizedIndent >= 0
				? normalizedIndent
				: prices.length + normalizedIndent;
		return prices[index];
	};

	const HighlightSpan = styled("span")({
		color: "#0260FE",
	});

	const CenteredTypography = styled(Typography)({
		justifyContent: "center",
		alignItems: "center",
		color: "#171663",
		fontFamily: "Poppins-Regular",
		fontSize: "30px",
		textAlign: "center",
	});

	const ArrowButton = styled(Button)(({dir}) => ({
		maxWidth: "5px",
		height: "75px",
		padding: 0,
		minWidth: "40px",
		width: "40px",
		opacity: "75%",
		transition: "opacity 0.3s ease",
		position: "absolute",
		top: "65%",
		transform: `translateY(-160%) translateX(${dir === 'right' ? '300px' : '0px'})`,
		zIndex: 10,
		display: "flex",
	}));

	return (
		<div>
			<HomeToolBar/>
			<Box
				sx={{
					marginTop: 0,
					marginBottom: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Typography
					variant="h5"
					style={{
						color: "#171663",
						fontFamily: "Poppins-Semibold",
						fontSize: "26px",
						margin: "30px",
					}}>
					Choose the plan that's right for you
				</Typography>
			</Box>

			<Box
				sx={{
					position: "relative",
					width: "100%",
					height: "650px",
					marginTop: "10px",
					paddingTop: "10px",
					background: "linear-gradient(to bottom right, #EDF0FA , #EDF0FA)",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						padding: "20px",
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{!isMobile ? (
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							spacing={2}>
							<PricingCard
								tier={getTier(prices, 0)}
								width="400px"
								height="550px"
							/>
							<PricingCard
								tier={getTier(prices, 1)}
								width="400px"
								height="550px"
							/>
							<PricingCard
								tier={getTier(prices, 2)}
								width="400px"
								height="550px"
							/>
						</Grid>) : (
						<div>
							<ArrowButton dir='left' className="arrow-button" onClick={handlePrevious}>
								&lt;
							</ArrowButton>
							<PricingCard
								tier={getTier(prices, 0)}
								width="300px"
								height="550px"
							/>
							<ArrowButton dir='right' className="arrow-button" onClick={handleNext}>
								&gt;
							</ArrowButton>
						</div>
					)}
				</Box>
			</Box>

			<Box
				sx={{
					marginTop: 4,
					marginBottom: 4,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Box sx={{textAlign: "center"}}>
					<CenteredTypography variant="h3">
						Unsure which plan is right for you?
					</CenteredTypography>
					<CenteredTypography variant="h3" gutterBottom>
						<HighlightSpan>Contact us</HighlightSpan> to find out more
					</CenteredTypography>
				</Box>
				<Button
					type="submit"
					href="/Register"
					sx={{
						color: "#FFFFFF",
						textTransform: "none",
						justifyContent: "center",
						alignItems: "center",
						cursor: "pointer",
						fontFamily: "Poppins-Regular",
						backgroundColor: "#0260FE",
						width: "400px",
						maxWidth: "90%",
						height: "50px",
						marginTop: "10px",
						"&:hover": {
							backgroundColor: "rgba(2, 96, 254, 0.6)",
							borderColor: "white",
							borderWidth: 2,
						},
					}}>
					Get started with a free demo account
				</Button>
			</Box>
		</div>
	);
}