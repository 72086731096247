import {toast} from "react-toastify";

class ApiServiceClass {
	static baseUrl = process.env.REACT_APP_API_BASE_URL;

	static getKey() {
		const rawLoginData = localStorage.getItem("loginData");
		const companyData = localStorage.getItem("companyData");
		const loginData = JSON.parse(rawLoginData);
		if (loginData.ApiKey !== "") {
			//console.log("fetched api key: " + loginData.ApiKey);
			return loginData.ApiKey;
		} else {
			console.log("unable to fetch api key");
			localStorage.removeItem("companyDetails");
			localStorage.removeItem("loginData");
		}
	}

	static async request(endpoint, requestBody, queryParams = {}) {
		if (!this.baseUrl) {
			console.error(
				"Base URL is not set. Check environment variable REACT_APP_API_BASE_URL."
			);
			return;
		}

		const url = new URL(`${this.baseUrl}${endpoint}`);
		Object.keys(queryParams).forEach((key) =>
			url.searchParams.append(key, queryParams[key])
		);
		const apiUrl = url.toString();
		console.log(`Request URL: ${apiUrl}`); // Log the constructed URL for debugging

		const apiKey = this.getKey();
		if (!apiKey) {
			console.error("API key is not available.");
			return;
		}

		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-functions-key": apiKey,
				},
				body: JSON.stringify(requestBody),
			});

			if (response.ok) {
				const responseBody = await response.text();
				try {
					return JSON.parse(responseBody);
				} catch (jsonError) {
					return responseBody;
				}
			}
		} catch (error) {
			console.error("API Response Error:", error);
			throw error;
		}
	}

	// Shared method to handle request with response status
	static async requestWithResponseStatus(
		endpoint,
		requestBody,
		queryParams = {}
	) {
		if (!this.baseUrl) {
			console.error(
				"Base URL is not set. Check environment variable REACT_APP_API_BASE_URL."
			);
			return;
		}

		const url = new URL(`${this.baseUrl}${endpoint}`);
		Object.keys(queryParams).forEach((key) =>
			url.searchParams.append(key, queryParams[key])
		);
		const apiUrl = url.toString();
		console.log(`Request URL: ${apiUrl}`); // Log the constructed URL for debugging

		const apiKey = this.getKey();
		if (!apiKey) {
			console.error("API key is not available.");
			return;
		}
		try {
			const response = await fetch(apiUrl, {
				method: "POST",

				headers: {
					"Content-Type": "application/json",
					"x-functions-key": apiKey,
				},
				body: JSON.stringify(requestBody),
			});
			const text = await response.text();
			return {status: response.status, text};
		} catch (error) {
			toast.error("Error sending message");
			console.error("Error sending message: ", error);
			throw error;
		}
	}

	static async CancelSubscription(requestBody) {
		return await this.request("/CancelSubscriptionHook", requestBody);
	}

	static async CancelPushNotification(requestBody) {
		return await this.requestWithResponseStatus(
			"/CancelPushNotification",
			requestBody
		);
	}

	static async ContactUs(requestBody) {
		return await this.requestWithResponseStatus("}/ContactUs", requestBody);
	}

	static async CreateCheckoutSession(requestBody) {
		return await this.request("/CreateCheckoutSession", requestBody);
	}

	static async CreateStaffMember(requestBody) {
		const apiUrl = `${this.baseUrl}/CreateStaffMember`;
		const apiKey = this.getKey();
		console.log("apiUrl: ", apiUrl);
		console.log("apiKey: ", apiKey);

		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-functions-key": apiKey,
			},
			body: JSON.stringify(requestBody),
		});
		return response;
	}

	static async CreatePushNotification(requestBody) {
		return await this.requestWithResponseStatus(
			"/CreatePushNotification",
			requestBody
		);
	}

	static async CreateStampReward(requestBody) {
		return await this.request("/CreateStampReward", requestBody);
	}

	static async DeleteAccount(requestBody) {
		return await this.requestWithResponseStatus(
			"/DeleteRewardSpaceAccount",
			requestBody
		);
	}

	static async DeleteStaffMember(requestBody) {
		return await this.request("/DeleteStaffMember", requestBody);
	}

	static async EditCompanyDetails(requestBody) {
		return await this.request("/EditCompanyDetails", requestBody);
	}

	static async EditStaffMember(requestBody) {
		try {
			return await this.request("/EditStaffMember", requestBody);
		} catch (error) {
			if (error.message.includes("Failed") && error.message.includes("409")) {
				toast.error("Email is already in use");
			} else {
				toast.error("apiError");
				console.error("Error:", error);
			}
			throw error;
		}
	}

	static async EditPaymentDetails(requestBody) {
		return await this.request("/ChangePaymentDetails", requestBody);
	}

	static async EditStampReward(requestBody) {
		return await this.request("/EditStampReward", requestBody);
	}

	static async ForgotPassword(requestBody) {
		return await this.requestWithResponseStatus(
			"/ForgotCompanyUserPassword",
			requestBody
		);
	}

	static async GetAvailableFonts() {
		return await this.request("/GetAvailableFonts", {});
	}

	static async CheckEmailUse(requestBody) {
		return await this.requestWithResponseStatus("/CheckEmailUse", requestBody);
	}

	static async GetCreatePushNotifCost(requestBody) {
		return await this.request("/GetCreatePushNotifCost", requestBody);
	}

	static async GetCustomers(requestBody) {
		return await this.request("/GetCustomers", requestBody);
	}

	static async GetPushNotifications(requestBody) {
		return await this.request("/GetPushNotifications", requestBody);
	}

	static async GetBasicStatistics(requestBody) {
		return await this.request("/GetBasicStatistics", requestBody);
	}

	static async GetBasicDashboardInformation(requestBody) {
		return await this.request("/GetBasicDashboardInformation", requestBody);
	}

	static async GetDashboardInformation(requestBody) {
		return await this.request("/GetDashboardInformation", requestBody);
	}

	static async GetDashboardGraph(requestBody) {
		return await this.request("/GetDashboardGraph", requestBody);
	}

	static async GetDetailedGraph(requestBody) {
		return await this.request("/GetDetailedGraph", requestBody);
	}

	static async GetCompanyDetails(requestBody) {
		return await this.request("/GetCompanyDetails", requestBody);
	}

	static async GetCompanyBasicTheme(requestBody) {
		return await this.request("/GetCompanyBasicTheme", requestBody);
	}

	static async GetCompanyMainTheme(requestBody) {
		return await this.request("/GetCompanyMainTheme", requestBody);
	}

	static async GetMarketingCredits(requestBody) {
		return await this.request("/GetMarketingCredits", requestBody);
	}

	static async GetRewardDetails(requestBody) {
		return await this.request("/GetRewardDetails", requestBody);
	}

	static async GetStaffMembers(requestBody) {
		return await this.request("/GetStaffMembers", requestBody);
	}

	static async GetSubscription(requestBody) {
		return await this.request("/GetPaymentInformation", requestBody);
	}

	static async ResetCompanyUserPasswordCheckCode(requestBody) {
		return await this.requestWithResponseStatus(
			"/ResetCompanyUserPasswordCheckCode",
			requestBody
		);
	}

	static async ResetPassword(requestBody) {
		return await this.requestWithResponseStatus(
			"/ForgotPasswordChangePassword",
			requestBody
		);
	}

	static async SendInviteEmployeeEmail(requestBody) {
		return await this.requestWithResponseStatus(
			"/SendInviteEmployeeEmail",
			requestBody
		);
	}

	static async SetupCompanyBasicTheme(requestBody) {
		return await this.request("/SetupCompanyBasicTheme", requestBody);
	}

	static async SetupCompanyMainTheme(requestBody) {
		return await this.request("/SetupCompanyMainTheme", requestBody);
	}

	static async VerifyCaptcha(requestBody) {
		const apiUrl = `${this.baseUrl}/VerifyCaptcha`;
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": ApiServiceClass.apiKey,
				},
				body: JSON.stringify(requestBody),
			});
			const text = await response.text();
			return {status: response.status, text};
		} catch (error) {
			console.error("Error:", error);
			throw error;
		}
	}

	static async GetMenuNavigationTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/GetNavigationMenuTheme",
			requestBody
		);
	}

	static async SaveMenuNavigationTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyNavigationMenuTheme",
			requestBody
		);
	}

	static async GetLoyaltyCardTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/GetLoyaltyCardTheme",
			requestBody
		);
	}

	static async SaveLoyaltyCardTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyLoyaltyCardTheme",
			requestBody
		);
	}

	static async GetCompanyLogo(requestBody) {
		return await this.requestWithResponseStatus("/GetCompanyLogo", requestBody);
	}

	static async SaveCompanyLogo(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyCompanyLogo",
			requestBody
		);
	}

	static async GetSplashTheme(requestBody) {
		return await this.requestWithResponseStatus("/GetSplashTheme", requestBody);
	}

	static async SaveSplashTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplySplashTheme",
			requestBody
		);
	}

	static async GetFontsTheme(requestBody) {
		return await this.requestWithResponseStatus("/GetThemeFonts", requestBody);
	}

	static async SaveFontsTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyThemeFonts",
			requestBody
		);
	}

	static async GetButtonsTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/GetThemeButtons",
			requestBody
		);
	}

	static async SaveButtonsTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyThemeButtons",
			requestBody
		);
	}

	static async GetColorsTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/GetThemeColours",
			requestBody
		);
	}

	static async SaveColorsTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyThemeColours",
			requestBody
		);
	}

	static async GetRewardSchemeTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/GetRewardSchemeTheme",
			requestBody
		);
	}

	static async SaveRewardSchemeTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyRewardSchemeTheme",
			requestBody
		);
	}

	static async GetSampleThemes(requestBody) {
		return await this.requestWithResponseStatus(
			"/GetSampleThemes",
			requestBody
		);
	}

	static async SaveFullTheme(requestBody) {
		return await this.requestWithResponseStatus("/ApplyFullTheme", requestBody);
	}

	static async GetMiscTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/GetMiscellaneousTheme",
			requestBody
		);
	}

	static async SaveMiscTheme(requestBody) {
		return await this.requestWithResponseStatus(
			"/ApplyMiscellaneousTheme",
			requestBody
		);
	}
}

export {ApiServiceClass};
