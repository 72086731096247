import React, {useEffect, useState} from "react";
import {Check} from "lucide-react";

const ThemeSelector = ({onThemeSelect, selectedTheme, sampleThemes}) => {
	const [selectedCard, setSelectedCard] = useState();

	useEffect(() => {
		if (selectedTheme == null) {
			setSelectedCard(null);
		}
	}, [selectedTheme]);

	const handleCardSelect = (id) => {
		setSelectedCard(id);
		onThemeSelect(id);
	};

	const gridStyle = {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gap: "15px",
		padding: "0px",
	};

	// Generate card data using sampleThemes
	const cardData = [
		{
			id: 0,
			name: "Default",
			subtitle: "Default Subtitle",
			primaryColor: sampleThemes[0].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[0].NavigationMenuBackgroundColour,
			logo: sampleThemes[0].CompanyLogo,
			accentColor: sampleThemes[0].RewardSchemeStampBackgroundColour,
			fontFamily: "Arial",
			primaryFontColor: "#616161",
			secondaryFontColor: "#757575",
		},
		{
			id: 1,
			name: "Glamour",
			subtitle: "Glamour Subtitle",
			primaryColor: sampleThemes[1].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[1].NavigationMenuBackgroundColour,
			logo: sampleThemes[1].CompanyLogo,
			accentColor: sampleThemes[1].RewardSchemeStampBackgroundColour,
			fontFamily: "Playfair Display",
			primaryFontColor: "#7A2C4C",
			secondaryFontColor: "#D36D94",
		},
		{
			id: 2,
			name: "Education",
			subtitle: "Education Subtitle",
			primaryColor: sampleThemes[2].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[2].NavigationMenuBackgroundColour,
			logo: sampleThemes[2].CompanyLogo,
			accentColor: sampleThemes[2].RewardSchemeStampBackgroundColour,
			fontFamily: "Merriweather",
			primaryFontColor: "#0D47A1",
			secondaryFontColor: "#1976D2",
		},
		{
			id: 3,
			name: "Coffee",
			subtitle: "Coffee Subtitle",
			primaryColor: sampleThemes[3].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[3].NavigationMenuBackgroundColour,
			logo: sampleThemes[3].CompanyLogo,
			accentColor: sampleThemes[3].RewardSchemeStampBackgroundColour,
			fontFamily: "Raleway",
			primaryFontColor: "#3B241A",
			secondaryFontColor: "#6F4C3E",
		},
		{
			id: 4,
			name: "Construction",
			subtitle: "Construction Subtitle",
			primaryColor: sampleThemes[4].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[4].NavigationMenuBackgroundColour,
			logo: sampleThemes[4].CompanyLogo,
			accentColor: sampleThemes[4].RewardSchemeStampBackgroundColour,
			fontFamily: "Quicksand",
			primaryFontColor: "#22223B",
			secondaryFontColor: "#4A4E69",
		},
		{
			id: 5,
			name: "Cleaning",
			subtitle: "Cleaning Subtitle",
			primaryColor: sampleThemes[5].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[5].NavigationMenuBackgroundColour,
			logo: sampleThemes[5].CompanyLogo,
			accentColor: sampleThemes[5].RewardSchemeStampBackgroundColour,
			fontFamily: "DM Sans",
			primaryFontColor: "#003D33",
			secondaryFontColor: "#00796B",
		},
		{
			id: 6,
			name: "Transportation",
			subtitle: "Transportation Subtitle",
			primaryColor: sampleThemes[6].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[6].NavigationMenuBackgroundColour,
			logo: sampleThemes[6].CompanyLogo,
			accentColor: sampleThemes[6].RewardSchemeStampBackgroundColour,
			fontFamily: "Varela Round",
			primaryFontColor: "#660066",
			secondaryFontColor: "#000000",
		},
		{
			id: 7,
			name: "Medical",
			subtitle: "Medical Subtitle",
			primaryColor: sampleThemes[7].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[7].NavigationMenuBackgroundColour,
			logo: sampleThemes[7].CompanyLogo,
			accentColor: sampleThemes[7].RewardSchemeStampBackgroundColour,
			fontFamily: "Montserrat",
			primaryFontColor: "#1A365D",
			secondaryFontColor: "#2A4365",
		},
	];

	return (
		<div style={gridStyle}>
			{cardData.map((card) => (
				<Card
					key={card.id}
					{...card}
					isSelected={selectedCard === card.id}
					onSelect={handleCardSelect}
				/>
			))}
		</div>
	);
};

const Card = ({
				  id,
				  name,
				  subtitle,
				  logo,
				  primaryColor,
				  secondaryColor,
				  accentColor,
				  primaryFontColor,
				  secondaryFontColor,
				  fontFamily,
				  isSelected,
				  onSelect,
			  }) => {
	const highlightColor = "#00cc00";

	const cardStyle = {
		width: "95%",
		height: "160px",
		backgroundColor: primaryColor,
		display: "flex",
		flexDirection: "column",
		padding: "10px",
		borderRadius: "12px",
		boxSizing: "border-box",
		position: "relative",
		cursor: "pointer",
		outline: isSelected ? `4px solid ${accentColor}` : "none",
		fontFamily: fontFamily,
		//transition: "all 0.1s ease-in-out",
		//boxShadow: `0 4px 6px -1px ${accentColor}20, 0 2px 4px -1px ${accentColor}10`,
		//border: `2px solid ${primaryFontColor}`,
		draggable: false,
	};

	const contentWrapperStyle = {
		flex: 1,
		display: "grid",
		gridTemplateRows: "45px 30px 25px 25px 1fr",
		alignItems: "center",
		gap: "4px",
	};

	const logoContainerStyle = (color) => ({
		backgroundColor: color,
		borderRadius: "10px",
		padding: "6px",
		height: "40px",
		width: "40px",
		margin: "0 auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	});

	const logoStyle = {
		width: "34px",
		height: "34px",
	};

	const titleStyle = {
		fontSize: "20px",
		fontWeight: "bold",
		color: primaryFontColor,
		fontFamily: fontFamily,
		textAlign: "center",
		margin: "0",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	const subtitleStyle = {
		fontSize: "14px",
		color: secondaryFontColor,
		fontFamily: fontFamily,
		textAlign: "center",
		marginTop: "-10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	const colorBoxesStyle = {
		display: "flex",
		justifyContent: "center",
		gap: "6px",
		padding: "4px",
		backgroundColor: "rgba(255, 255, 255, 1.0)",
		borderRadius: "20px",
		marginTop: "-5px",
		width: "50%",
		marginLeft: "25%",
	};

	const colorBoxStyle = {
		minWidth: "20px",
		minHeight: "20px",
		border: "2px solid rgba(0, 0, 0, 1.0)",
		borderRadius: "50%",
	};

	return (
		<div style={cardStyle} onClick={() => onSelect(id)}>
			{isSelected && (
				<Check
					style={{
						position: "absolute",
						top: "10px",
						right: "10px",
						color: highlightColor,
						size: 30,
					}}
				/>
			)}
			<div style={contentWrapperStyle}>
				<div style={logoContainerStyle(accentColor)}>
					<img
						src={`data:image/svg+xml;base64,${logo}`}
						alt={`${name} logo`}
						style={logoStyle}
					/>
				</div>
				<h3 style={titleStyle}>{name}</h3>
				<p style={subtitleStyle}>{subtitle}</p>
				<div style={colorBoxesStyle}>
					<div
						style={{
							...colorBoxStyle,
							backgroundColor: primaryColor,
							title: "Primary Color",
						}}
					/>
					<div
						style={{
							...colorBoxStyle,
							backgroundColor: secondaryColor,
							title: "Secondary Color",
						}}
					/>
					<div
						style={{
							...colorBoxStyle,
							backgroundColor: accentColor,
							title: "Accent Color",
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default ThemeSelector;
