import {Button, Card, CardContent, CardHeader, Divider, Stack,} from "@mui/material";
import {ReactComponent as Icon} from "../tools/icons/account-icon.svg";

export const SettingsAccountPanel = ({
										 title,
										 subheader,
										 emailAddress,
										 handlePasswordChange,
										 handleClickDelete,
									 }) => {
	return (
		<div style={{padding: "10px"}}>
			<Card sx={{height: "1%"}}>
				<CardHeader
					title={title}
					subheader={subheader}
					avatar={<Icon style={{width: "50px", height: "40px"}}/>}
				/>

				<Divider/>
				<CardContent>
					<div style={{marginLeft: "5px"}}>
						Account Email: <strong>{emailAddress}</strong>
					</div>

					<Stack
						direction="row"
						spacing={2}
						sx={{maxWidth: "100%", justifyContent: "left", marginTop: "10px"}}
					>
						<Button
							type="submit"
							variant="contained"
							sx={{
								mt: 1,
								mb: 4,
								width: "15%",
							}}
							onClick={handlePasswordChange}
						>
							Change password
						</Button>
						<Button
							type="submit"
							variant="contained"
							sx={{
								mt: 1,
								mb: 4,
								width: "15%",
							}}
							onClick={handleClickDelete}
						>
							Delete Account
						</Button>
					</Stack>
				</CardContent>

				<Divider/>
				{/* <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained">Update</Button>
        </CardActions> */}
			</Card>
		</div>
	);
};
