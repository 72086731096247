import React, {useEffect, useRef} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import lottie from "lottie-web";
import VideoIntro from "../tools/video/rewardspacevideo.mp4";
import Paint from "../tools/animation/paint.json";
import Mobile from "../tools/animation/mobile.json";
import Management from "../tools/animation/manage.json";
import Marketing from "../tools/animation/marketing.json";
import Rocket from "../tools/animation/rocket.json";
import {HomeToolBar} from "../components/home-toolbar";
import {Copyright} from "../components/copyright";

const features = [
	{
		id: "mobile",
		title: "Mobile App",
		description:
			"Elevate your customer loyalty with a personalized mobile app tailored to your brand, providing seamless access and exclusive perks for your loyal customers.",
		animationData: Mobile,
	},
	{
		id: "customization",
		title: "Customization",
		description:
			"Unlock the power of full customization for your app, hassle-free and without any coding required.",
		animationData: Paint,
	},
	{
		id: "marketing",
		title: "Marketing",
		description:
			"Utilise our suite of tools, empowering you to engage customers through targeted push notifications, SMS, and email campaigns, maximizing your reach and impact.",
		animationData: Marketing,
	},
	{
		id: "management",
		title: "Management",
		description:
			"We own and manage the whole technical system from start to finish allowing you to focus on what truly matters.",
		animationData: Management,
	},
];

export default function RewardSpaceHome() {
	const videoRef = useRef(null);

	useEffect(() => {
		features.forEach((feature) => {
			lottie.loadAnimation({
				container: document.getElementById(`lottie-container-${feature.id}`),
				renderer: "svg",
				loop: true,
				autoplay: true,
				animationData: feature.animationData,
			});
		});

		// Load Rocket animation outside the loop
		lottie.loadAnimation({
			container: document.getElementById("lottie-container-rocket"),
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: Rocket,
		});

		const video = videoRef.current;
		if (video) {
			video.addEventListener("ended", () => {
				video.play();
			});
		}

		return () => {
			if (video) {
				video.removeEventListener("ended", () => {
					video.play();
				});
			}
		};
	}, []);

	return (
		<div
			style={{
				maxWidth: "100%",
				maxHeight: "100%",
				minHeight: "100vh",
				background: "#FFFFFF",
				display: "flex",
				overflowX: "hidden",
				flexDirection: "column",
			}}
		>
			{/* ToolBar */}
			<HomeToolBar/>

			<Box
				sx={{
					marginTop: 3,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div style={{height: 50}}/>

				<Box
					sx={{
						padding: 2,
						flexWrap: "wrap",
						maxWidth: "75%",
						display: "flex",
						justifyContent: "center",
						verticalAlign: "top",
						flexDirection: "row",
						alignItems: "center", // Center vertically
					}}
				>
					<Box
						sx={{
							width: "50%",
							minWidth: "350px",
						}}
					>
						<Typography
							variant="h4"
							gutterBottom
							style={{
								background: "#FFC107",
								WebkitBackgroundClip: "text",
								color: "#171663",
								fontFamily: "Poppins-Medium",
								textAlign: "start",
								fontSize: "50px",
							}}
						>
							Elevating <span style={{color: "#0260FE"}}>Loyalty </span>
							<br/>
							Empowering Business <br/>
							<span style={{color: "#0260FE"}}>Growth</span> <br/>
						</Typography>

						<Typography
							gutterBottom
							style={{
								background: "#FFC107",
								WebkitBackgroundClip: "text",
								color: "#171663",
								fontFamily: "Poppins-Regular",
								textAlign: "start",
								fontSize: "18px",
							}}
						>
							Leverage our tools and expertise to grow your business, revenue
							and customer loyalty. Our unique platform enables you to create
							personalized and engaging experiences and turn first time
							customers into lifelong ambassadors.
							<br/>
							<br/>
						</Typography>
					</Box>

					<Box
						sx={{
							justifyContent: "center",
							borderRadius: "15px",
							width: "50%",
							minWidth: "350px",
							alignItems: "center",
							display: "flex",
							overflow: "hidden",
						}}
					>
						<video
							ref={videoRef}
							src={VideoIntro}
							autoPlay
							loop
							muted
							style={{
								maxWidth: "650px",
								maxHeight: "450px",
								width: "100%",
								height: "100%",
								objectFit: "contain",
								borderRadius: "20px",
								display: "block",
							}}
						></video>
					</Box>
				</Box>

				<div style={{height: 90}}/>

				<div
					style={{
						width: "110%", // Adjusted width
						// height: "52vh",
						background: "linear-gradient(to bottom right, #EDF0FA , #EDF0FA)",
						display: "flex",
						flexDirection: "column",
						transform: "rotate(-2deg)",
						transition: "all 0.5s ease",
						boxSizing: "border-box", // Ensure box-sizing
						margin: 0, // Reset margin
						padding: 0, // Reset padding
						paddingBottom: "50px",
					}}
				>
					<Box
						sx={{
							marginTop: 5,
							display: "flex",
							transform: "rotate(2deg)",
							flexDirection: "row", // Change flex direction to "row"
							alignItems: "center",
							justifyContent: "center", // Center the content horizontally
						}}
					>
						<Typography
							style={{
								background: "#FFC107",
								WebkitBackgroundClip: "text",
								color: "#171663",
								fontFamily: "Poppins-Medium",
								textAlign: "center",
								fontSize: "50px",
								paddingBottom: "30px",
							}}
						>
							Why us?
						</Typography>
					</Box>

					<Box
						sx={{
							transform: "rotate(2deg)",
							marginTop: 0,
							display: "flex",
							flexDirection: "row", // Keeps flex direction as row
							alignItems: "flex-start",
							justifyContent: "center", // Center the content horizontally
							flexWrap: "wrap", // Enables wrapping of items
						}}
					>
						{features.map((feature) => (
							<Box
								key={feature.id}
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									margin: "0 50px",
									textAlign: "center",
									marginBottom: "20px", // Adds space between rows
									"@media (max-width: 600px)": {
										margin: "10px 20px", // Adjust margins for narrow screens
									},
								}}
							>
								<Box
									id={`lottie-container-${feature.id}`}
									sx={{width: "90px", height: "90px", marginBottom: "10px"}}
								></Box>
								<Typography
									sx={{
										color: "#171663",
										textAlign: "center",
										fontFamily: "Poppins-Semibold",
										fontSize: "20px",
										//backgroundColor: " #00000",
									}}
								>
									{feature.title}
								</Typography>
								<Typography
									sx={{
										width: "300px",
										marginTop: "5px",
										WebkitBackgroundClip: "text",
										color: "#171663",
										fontFamily: "Poppins-Medium",
										fontSize: "16px",
										"@media (max-width: 600px)": {
											width: "auto", // Adjust width for narrow screens
											padding: "0 10px", // Adds padding for better readability
										},
									}}
								>
									{feature.description}
								</Typography>
							</Box>
						))}
					</Box>
				</div>

				<Box
					id={`lottie-container-rocket`}
					sx={{
						width: "90px",
						height: "90px",
						marginTop: "100px",
						marginBottom: "10px",
					}}
				></Box>

				<Typography
					style={{
						fontFamily: "Poppins-Semibold",
						textAlign: "center",
						marginTop: "20px",
						color: "#171663",
						fontSize: "30px",
					}}
				>
					Launch your business to new heights with Reward Space, <br></br> the
					ultimate mobile rewards app
				</Typography>

				<Button
					type="submit"
					href="/Register"
					fontSize="20px"
					variant="contained"
					sx={{
						fontFamily: "Poppins-SemiBold",
						mt: 4,
						mb: 15,
						height: "50px",
						width: "20%",
						minWidth: "300px",
					}}
				>
					Begin your journey at no cost.
				</Button>

				{/* Achievements Section */}
				<div
					style={{
						width: "100%",
						background: "linear-gradient(to bottom right, #EDF0FA, #FFFFFF)",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						paddingBottom: "50px",
						paddingTop: "20px",
						boxSizing: "border-box",
					}}
				>
					<Box
						sx={{
							marginTop: 5,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography
							style={{
								background: "#FFC107",
								WebkitBackgroundClip: "text",
								color: "#171663",
								fontFamily: "Poppins-Medium",
								textAlign: "center",
								fontSize: "30px",
								paddingBottom: "20px",
							}}
						>
							Our Commitment to Quality
						</Typography>

						<Box
							sx={{
								marginTop: 0,
								display: "flex",
								flexDirection: "row", // Keeps flex direction as row
								alignItems: "flex-start",
								justifyContent: "center", // Center the content horizontally
								flexWrap: "wrap", // Enables wrapping of items
							}}
						>
							<a href="https://www.softwareadvice.com/product/524667-Reward-Space/reviews/">
								<img
									border="0"
									style={{
										marginLeft: 15,
										marginTop: 25,
										width: "220px", // Consistent width
										height: "80px", // Consistent height
										borderRadius: "20px",
										objectFit: "cover", // Ensures the image fills the area without distortion
									}}
									src="https://brand-assets.softwareadvice.com/badge/105caa42-d697-4ce5-a57d-5344b6bb3899.png"
								/>
							</a>

							<img
								src="https://www-assets.perkbox.com/media/10587/i960/4e4c8aae431ff0d94cae.png"
								alt="Achievement"
								style={{
									marginLeft: 15,
									marginTop: 0,
									width: "150px", // Consistent width
									height: "150px", // Consistent height
									borderRadius: "20px",
									objectFit: "cover", // Ensures the image fills the area without distortion
								}}
							/>

							<a href="https://www.capterra.com/p/10025916/Reward-Space/reviews/">
								<img
									style={{
										marginLeft: 15,
										marginTop: 25,
										width: "220px", // Consistent width
										height: "70px", // Consistent height
										borderRadius: "20px",
										objectFit: "cover", // Ensures the image fills the area without distortion
									}}
									border="0"
									src="https://brand-assets.capterra.com/badge/25d1aaf7-833b-4cd1-831e-3005f37e9421.svg"
								/>
							</a>
						</Box>
					</Box>

					{/* Footer */}
					<Grid item xs align="center" marginTop={8}>
						<Copyright
							sx={{
								fontFamily: "Poppins-Medium",
								color: "#171663",
							}}
						/>
					</Grid>
				</div>
			</Box>
		</div>
	);
}
