import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from "@mui/icons-material/Send";

export default function NotificationReviewPopup({
													open,
													onClose,
													notificationData,
													handleConfirmSchedule,
													pushCostData,
													marketingCredits,
													getGroupLabel,
													getGroup,
												}) {
	// Destructure properties from notificationData with default values
	const {
		title = 'Untitled',
		message = 'No message provided.',
		customerGroup = 1,
		scheduledDate = '',
		scheduledTime = 'Not scheduled',
		isScheduledNow = false,
		cost = 3,
		newBalance = 15
	} = notificationData;

	const {
		NumCredits,
		NumUsers
	} = pushCostData;

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{
			width: "70%",
			maxWidth: "1200px",
			height: "100%",
			margin: "auto",
			overflow: "hidden" // Prevent scrollbars
		}}
				PaperProps={{
					sx: {
						height: "80vh", // Control the height of the actual panel
						minHeight: "400px",
					}
				}}
		>
			<DialogTitle sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: '#f5f5f5',
				padding: '16px',
			}}>
				<Typography variant="h6" component="div">
					Review Notification
				</Typography>
				<IconButton onClick={onClose} sx={{color: '#757575', padding: 1}}>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<Divider/>
			<DialogContent sx={{
				padding: 3,
				height: "100%",  // Adjust to take up full height minus header and footer
				overflowY: "auto"  // Enable scrolling inside the dialog content
			}}>

				{/* <Typography variant="subtitle1" color="textSecondary" gutterBottom>Title</Typography>
                <Typography variant="body1" sx={{ fontWeight: '500' }}>{title}</Typography> */}

				<Typography variant="subtitle1" color="textSecondary" gutterBottom
							sx={{marginTop: 2}}>Message</Typography>
				<Typography variant="body1" sx={{whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontWeight: '400'}}>
					{message}
				</Typography>

				<Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{marginTop: 2}}>Target
					Group</Typography>
				<Typography variant="body1"
							sx={{fontWeight: '500'}}>{getGroupLabel(customerGroup)} ({getGroup(customerGroup).NumUsers})</Typography>

				<Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{marginTop: 2}}>Scheduled
					Date</Typography>
				<Typography variant="body1"
							sx={{fontWeight: '500'}}>{isScheduledNow ? "Now" : scheduledDate}</Typography>

				<Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{marginTop: 2}}>Scheduled
					Time</Typography>
				<Typography variant="body1"
							sx={{fontWeight: '500'}}>{isScheduledNow ? "Now" : scheduledTime}</Typography>

				<Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{marginTop: 2}}>Current
					Balance</Typography>
				<Typography variant="body1" sx={{fontWeight: '500'}}>{marketingCredits.RemainingCredits}</Typography>

				<Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{marginTop: 2}}>Credits
					Required</Typography>
				<Typography variant="body1" sx={{fontWeight: '500'}}>{getGroup(customerGroup).NumCredits}</Typography>

				<Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{marginTop: 2}}>New
					Balance</Typography>
				<Typography variant="body1" sx={{
					fontWeight: '500',
					color: (marketingCredits.RemainingCredits - getGroup(customerGroup).NumCredits) < 0 ? 'red' : 'inherit',
				}}>
					{marketingCredits.RemainingCredits - getGroup(customerGroup).NumCredits}
				</Typography>
			</DialogContent>
			<DialogActions sx={{padding: 2, justifyContent: 'space-between', backgroundColor: '#f5f5f5'}}>
				<Button onClick={onClose} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					endIcon={<SendIcon/>}
					onClick={handleConfirmSchedule}
				>
					{isScheduledNow ? "Send Now" : "Schedule"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}


// Prop types validation
NotificationReviewPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	notificationData: PropTypes.shape({
		title: PropTypes.string,
		message: PropTypes.string,
		groupId: PropTypes.number,
		scheduledDate: PropTypes.string,
		scheduledTime: PropTypes.string,
		isScheduledNow: PropTypes.bool,
		cost: PropTypes.number,
		newBalance: PropTypes.number,
	}).isRequired,
	handleConfirmSchedule: PropTypes.func.isRequired,
};
