import React, {useState} from "react";
import Styles from "../resources/styles";
import FontBox from "./sharedcomponents/FontBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import {ApiServiceClass} from "../../../components/api-service";
import {toast} from "react-toastify";
import NumberBox from "./sharedcomponents/NumberBox";

function FontsContent({fonts, designManager}) {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [fontContent, setFontContent] = useState({
		appHeaderFont: designManager.getCurrentDesign().appHeaderFont,
		primaryThemeFont: designManager.getCurrentDesign().primaryThemeFont,
		buttonsFont: designManager.getCurrentDesign().buttonsFont,
		appHeaderFontSize: designManager.getCurrentDesign().appHeaderFontSize,
		primaryThemeFontSize: designManager.getCurrentDesign().primaryThemeFontSize,
		buttonsFontSize: designManager.getCurrentDesign().buttonsFontSize,
	});

	const handleFontChange = (fontProperty, newFont) => {
		console.log(`Changing '${fontProperty}' to`, newFont);
		designManager.setUnsavedChanges({[fontProperty]: newFont});

		// Update local state for live preview
		setFontContent((prevState) => ({
			...prevState,
			[fontProperty]: newFont,
		}));
		setSaveDisabled(false);
	};

	const getFontIdByTechnicalName = (technicalName) => {
		const font = fonts.find((font) => font.FontTechnicalName === technicalName);
		return font ? font.Id : null; // Return the Id if found, otherwise return null
	};

	const handleSave = async () => {
		try {
			if (!designManager.hasUnsavedChanges()) {
				console.log("No changes to save.");
				return;
			}
			const updatedDesign = designManager.getUnsavedDesign();
			const requestBody = {
				CompanyId: designManager.getCompanyId(),
				AppHeaderFont: getFontIdByTechnicalName(updatedDesign.appHeaderFont),
				PrimaryFont: getFontIdByTechnicalName(updatedDesign.primaryThemeFont),
				ButtonsFont: getFontIdByTechnicalName(updatedDesign.buttonsFont),
				HeaderFontSize: updatedDesign.appHeaderFontSize,
				PrimaryFontSize: updatedDesign.primaryThemeFontSize,
				ButtonsFontSize: updatedDesign.buttonsFontSize,
			};
			console.log("Calling SaveFontsTheme API:", requestBody);
			const response = await ApiServiceClass.SaveFontsTheme(requestBody);
			if (response.status >= 400) {
				throw {
					status: response.status,
					message: `HTTP Error: ${response.status}`,
				};
			}
			designManager.updateDesign(updatedDesign);
			toast.success("Fonts Theme Saved", {autoClose: 1500});
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Saving Font Theme: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		} finally {
			setSaveDisabled(true);
		}
	};

	const handleReset = () => {
		console.log("Resetting design");
		designManager.resetUnsavedChanges();

		// Update state with reset values
		setFontContent({
			appHeaderFont: designManager.getCurrentDesign().appHeaderFont,
			primaryThemeFont: designManager.getCurrentDesign().primaryThemeFont,
			buttonsFont: designManager.getCurrentDesign().buttonsFont,
			appHeaderFontSize: designManager.getCurrentDesign().appHeaderFontSize,
			primaryThemeFontSize:
			designManager.getCurrentDesign().primaryThemeFontSize,
			buttonsFontSize: designManager.getCurrentDesign().buttonsFontSize,
		});
		setSaveDisabled(true);
	};

	return (
		<div style={Styles.panel}>
			<div style={Styles.contentWrapper}>
				<div style={Styles.sectionTitle}>App Header</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Font style
						<FontBox
							fonts={fonts}
							selectedFont={fontContent.appHeaderFont} // Pass selectedFont
							onFontChange={(newFont) =>
								handleFontChange("appHeaderFont", newFont)
							}
						/>
					</div>
					<div style={Styles.gridItemRight}>
						Font size
						<NumberBox
							defaultValue={fontContent.appHeaderFontSize}
							maxValue={16}
							minValue={10}
							onChange={(text) => handleFontChange("appHeaderFontSize", text)}
						/>
					</div>
				</div>

				<div style={Styles.sectionTitle}>Primary Font Theme</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Font Style
						<FontBox
							fonts={fonts}
							selectedFont={fontContent.primaryThemeFont} // Pass selectedFont
							onFontChange={(newFont) =>
								handleFontChange("primaryThemeFont", newFont)
							}
						/>
					</div>
					<div style={Styles.gridItemRight}>
						Font size
						<NumberBox
							defaultValue={fontContent.primaryThemeFontSize}
							maxValue={16}
							minValue={10}
							onChange={(text) =>
								handleFontChange("primaryThemeFontSize", text)
							}
						/>
					</div>
				</div>
				<div style={Styles.sectionTitle}>Buttons</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Font Style
						<FontBox
							fonts={fonts}
							selectedFont={fontContent.buttonsFont} // Pass selectedFont
							onFontChange={(newFont) =>
								handleFontChange("buttonsFont", newFont)
							}
						/>
					</div>

					<div style={Styles.gridItemRight}>
						Font size
						<NumberBox
							defaultValue={fontContent.buttonsFontSize}
							maxValue={16}
							minValue={10}
							onChange={(text) => handleFontChange("buttonsFontSize", text)}
						/>
					</div>
				</div>
			</div>
			<div style={Styles.actions}>
				<button style={Styles.resetBtn} onClick={handleReset}>
					Reset
					<Tooltip
						title={
							<span style={{display: "block", textAlign: "center"}}>
                This will reset all content to the last saved values
              </span>
						}
						arrow
					>
						<HelpOutlineIcon
							style={{
								marginLeft: "4px",
								fontSize: "17px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				</button>
				<button
					style={{
						...Styles.applyBtn,
						backgroundColor: saveDisabled ? "gray" : "#28a745",
						opacity: saveDisabled ? 0.5 : 1,
						cursor: saveDisabled ? "not-allowed" : "pointer",
					}}
					disabled={saveDisabled} // Only disable if no unsaved changes
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default FontsContent;
