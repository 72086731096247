import Navbar from "./navbar.js";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function Help() {
	let navigate = useNavigate();
	const rawCompanyDetails = localStorage.getItem("companyData");
	const companyDetails = JSON.parse(rawCompanyDetails);
	const rawLoginData = localStorage.getItem("loginData");
	const loginData = JSON.parse(rawLoginData);

	useEffect(() => {
		if (companyDetails === null || loginData === null) {
			console.log("redirecting to /Login");
			navigate("/Login");
		}
	}, []);

	const [faqs, setFaqs] = useState([
		{
			question: "What is Reward Space?",
			answer:
				"Reward-Space.co.uk is a platform designed to help you grow your business and customer loyalty. We create a unique  mobile app for your business available on iOS and Android that your customers can download and use.",
			open: false,
		},
		{
			question: "How do I get started?",
			answer:
				"Firstly, create an account and sign up to a plan! Then setup your own custom mobile application on our <a href='/Design'>design</a> page. We will do everything else for you! ",
			open: false,
		},
		{
			question: "Where is Reward-Space.co.uk based?",
			answer: "We are based in the UK",
			open: false,
		},
		{
			question:
				"How long will it take for me to get my own app available on the iOS or Android app store?",
			answer:
				"Once you have setup your account it should be available on the store within 2 weeks",
			open: false,
		},
		{
			question: "How much does this cost?",
			answer:
				"There are different pricing plans that can be viewed on our pricing page <a href='/Pricing'>here</a>",
			open: false,
		},
		{
			question: "How can I contact you directly?",
			answer: "You can contact us directly at support@reward-space.co.uk",
			open: false,
		},
	]);

	const handleLinkClick = (event) => {
		// Stop the propagation of the click event
		event.stopPropagation();
	};

	if (companyDetails == null) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<CircularProgress/>
			</div>
		);
	}
	const toggleFAQ = (index) => {
		setFaqs(
			faqs.map((faq, i) => {
				if (i === index) {
					faq.open = !faq.open;
				} else {
					faq.open = false;
				}

				return faq;
			})
		);
	};

	return (
		<Navbar name="Help & Support" title="FAQ">
			<div style={{position: "relative", left: "0px", marginTop: "2%"}} className="faqs">
				{faqs.map((faq, index) => (
					<FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}/>
				))}
			</div>
		</Navbar>
	);
}

const FAQ = ({faq, index, toggleFAQ}) => {
	return (
		<div
			className={"faq " + (faq.open ? "open" : "")}
			key={index}
			onClick={() => toggleFAQ(index)}
		>
			<div className="faq-question">{faq.question}</div>
			<div
				className="faq-answer"
				onClick={(event) => event.stopPropagation()}
				dangerouslySetInnerHTML={{__html: faq.answer}}
			/>
		</div>
	);
};
