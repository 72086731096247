import React, {useState} from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import Styles from "../resources/styles";
import ImageBox from "./sharedcomponents/ImageBox";
import {toast} from "react-toastify";
import {ApiServiceClass} from "../../../components/api-service";

function LogoContent({designManager}) {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [logoContent, setLogoContent] = useState({
		companyLogo: designManager.getCurrentDesign().companyLogo,
		companyLogoFileType: designManager.getCurrentDesign().companyLogoFileType,
	});

	const handleReset = () => {
		console.log("Resetting design");
		designManager.resetUnsavedChanges();
		setLogoContent({
			companyLogo: designManager.getCurrentDesign().companyLogo,
			companyLogoFileType: designManager.getCurrentDesign().companyLogoFileType,
		});
		setSaveDisabled(true);
	};

	const handleImageChange = (newLogo, newFileName, newFileType) => {
		console.log("[UNSAVED] Changed companyLogo to:", newLogo);
		console.log("[UNSAVED] Changed companyLogoFileType to:", newFileType);
		designManager.setUnsavedChanges({["companyLogo"]: newLogo});
		designManager.setUnsavedChanges({["companyLogoFileType"]: newFileType});

		setLogoContent((prevContent) => ({
			...prevContent,
			companyLogo: newLogo, // Update with the new base64 image
			companyLogoFileType: newFileType, // Update with the new file type (e.g., image/png, image/jpeg)
		}));
		setSaveDisabled(false);
	};

	const handleSave = async () => {
		try {
			if (!designManager.hasUnsavedChanges()) {
				console.log("No changes to save.");
				return;
			}
			const updatedDesign = designManager.getUnsavedDesign();
			const requestBody = {
				CompanyId: designManager.getCompanyId(),
				companyLogo: updatedDesign.companyLogo,
				CompanyLogoFileType: updatedDesign.companyLogoFileType,
				RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
				FromWeb: true,
			};
			console.log("Calling SaveLogoTheme API:", requestBody);
			const response = await ApiServiceClass.SaveCompanyLogo(requestBody);
			if (response.status >= 400) {
				throw {
					status: response.status,
					message: `HTTP Error: ${response.status}`,
				};
			}
			designManager.updateDesign(updatedDesign);
			toast.success("Logo Theme Saved", {autoClose: 1500});
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Saving Logo Theme: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		} finally {
			setSaveDisabled(true);
		}
	};

	return (
		<div style={Styles.panel}>
			<div style={Styles.contentWrapper}>
				<div style={Styles.sectionTitle}>Image</div>
				<div style={Styles.content}>
					<ImageBox
						initialImage={logoContent.companyLogo}
						initialFileType={logoContent.companyLogoFileType}
						onImageChange={(newImage, newFileName, newFileType) =>
							handleImageChange(newImage, newFileName, newFileType)
						}
					/>
				</div>
			</div>
			<div style={Styles.actions}>
				<button style={Styles.resetBtn} onClick={handleReset}>
					Reset
					<Tooltip
						title={
							<span style={{display: "block", textAlign: "center"}}>
                This will reset all content to the last saved values
              </span>
						}
						arrow
					>
						<HelpOutlineIcon
							style={{
								marginLeft: "4px",
								fontSize: "17px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				</button>
				<button
					style={{
						...Styles.applyBtn,
						backgroundColor: saveDisabled ? "gray" : "#28a745",
						opacity: saveDisabled ? 0.5 : 1,
						cursor: saveDisabled ? "not-allowed" : "pointer",
					}}
					disabled={saveDisabled} // Only disable if no unsaved changes
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default LogoContent;
