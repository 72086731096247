import React, {useState} from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import FontBox from "./sharedcomponents/FontBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import {ApiServiceClass} from "../../../components/api-service";
import {toast} from "react-toastify";

function ButtonsContent({fonts, designManager}) {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [buttonsContent, setButtonsContent] = useState({
		buttonBackgroundColor:
		designManager.getCurrentDesign().buttonBackgroundColor,
		buttonFontColor: designManager.getCurrentDesign().buttonFontColor,
		buttonsFont: designManager.getCurrentDesign().buttonsFont,
	});

	const handleContentChange = (contentProperty, newContentProperty) => {
		console.log(`Changing '${contentProperty}' to`, newContentProperty);
		designManager.setUnsavedChanges({[contentProperty]: newContentProperty});

		setButtonsContent((prevContent) => ({
			...prevContent,
			[contentProperty]: newContentProperty, // Dynamically update the property
		}));
		setSaveDisabled(false);
	};

	const getFontIdByTechnicalName = (technicalName) => {
		const font = fonts.find((font) => font.FontTechnicalName === technicalName);
		return font ? font.Id : null; // Return the Id if found, otherwise return null
	};

	const handleSave = async () => {
		try {
			if (!designManager.hasUnsavedChanges()) {
				console.log("No changes to save.");
				return;
			}
			const updatedDesign = designManager.getUnsavedDesign();
			const requestBody = {
				CompanyId: designManager.getCompanyId(),
				ButtonsBackgroundColour: updatedDesign.buttonBackgroundColor,
				ButtonsFontColour: updatedDesign.buttonFontColor,
				ButtonsFont: getFontIdByTechnicalName(updatedDesign.buttonsFont),
			};
			console.log("Calling SaveButtonsTheme API:", requestBody);
			const response = await ApiServiceClass.SaveButtonsTheme(requestBody);
			if (response.status >= 400) {
				throw {
					status: response.status,
					message: `HTTP Error: ${response.status}`,
				};
			}
			designManager.updateDesign(updatedDesign);
			toast.success("Buttons Theme Saved", {autoClose: 1500});
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Saving Buttons Theme: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		} finally {
			setSaveDisabled(true);
		}
	};

	const handleReset = () => {
		console.log("Resetting design");
		designManager.resetUnsavedChanges();

		setButtonsContent({
			buttonBackgroundColor:
			designManager.getCurrentDesign().buttonBackgroundColor,
			buttonFontColor: designManager.getCurrentDesign().buttonFontColor,
			buttonsFont: designManager.getCurrentDesign().buttonsFont,
		});
		setSaveDisabled(true);
	};

	return (
		<div style={Styles.panel}>
			<div style={Styles.contentWrapper}>
				<div style={Styles.sectionTitle}>Colours</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Background Colour
						<ColourBox
							initColor={buttonsContent.buttonBackgroundColor} // Access from colors object
							onColorChange={(newColor) =>
								handleContentChange("buttonBackgroundColor", newColor)
							}
						/>
					</div>
					<div style={Styles.gridItemRight}>
						Font Colour
						<ColourBox
							initColor={buttonsContent.buttonFontColor} // Access from colors object
							onColorChange={(newColor) =>
								handleContentChange("buttonFontColor", newColor)
							}
						/>
					</div>
				</div>
				<div style={Styles.sectionTitle}>Fonts</div>
				<div style={Styles.content}>
					Font Style
					<FontBox
						fonts={fonts}
						selectedFont={buttonsContent.buttonsFont} // Pass selectedFont
						onFontChange={(newFont) =>
							handleContentChange("buttonsFont", newFont)
						}
					/>
				</div>
			</div>
			<div style={Styles.actions}>
				<button style={Styles.resetBtn} onClick={handleReset}>
					Reset
					<Tooltip
						title={
							<span style={{display: "block", textAlign: "center"}}>
                This will reset all content to the last saved values
              </span>
						}
						arrow
					>
						<HelpOutlineIcon
							style={{
								marginLeft: "4px",
								fontSize: "17px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				</button>
				<button
					style={{
						...Styles.applyBtn,
						backgroundColor: saveDisabled ? "gray" : "#28a745",
						opacity: saveDisabled ? 0.5 : 1,
						cursor: saveDisabled ? "not-allowed" : "pointer",
					}}
					disabled={saveDisabled} // Only disable if no unsaved changes
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default ButtonsContent;
