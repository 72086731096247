import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Navbar from "./navbar";
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/system";
import {Box, Button, Grid, Typography} from "@mui/material";
import {prices} from "../components/price-plans";
import PricingCard from "../components/pricing-card";
import useIsMobile from "../components/mobile";

export default function Upgrade() {
	const [activeIndex, setActiveIndex] = useState(0);
	const isMobile = useIsMobile();

	const handleNext = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === prices.length - 1 ? 0 : prevIndex + 1
		);
	};

	const handlePrevious = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === 0 ? prices.length - 1 : prevIndex - 1
		);
	};

	const getTier = (prices, indent) => {
		if (prices.length === 0) return null;
		const normalizedIndent = (activeIndex + indent) % prices.length;
		const index =
			normalizedIndent >= 0
				? normalizedIndent
				: prices.length + normalizedIndent;
		return prices[index];
	};

	const ArrowButton = styled(Button)(({dir}) => ({
		width: "50px",
		height: "75px",
		opacity: "75%",
		transition: "opacity 0.3s ease",
		position: "absolute",
		top: "65%",
		transform: `translateY(-160%) translateX(${
			dir === "right" ? "250px" : "-250px"
		})`,
		zIndex: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	}));

	const rawCompanyDetails = localStorage.getItem("companyData");
	const companyDetails = JSON.parse(rawCompanyDetails);
	const rawLoginData = localStorage.getItem("loginData");
	const loginData = JSON.parse(rawLoginData);

	const navigate = useNavigate();

	useEffect(() => {
		if (companyDetails === null || loginData === null) {
			console.log("redirecting to /Login");
			navigate("/Login");
		}
	}, [companyDetails, navigate]);

	if (companyDetails == null) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<CircularProgress/>
			</div>
		);
	}

	if (!prices || !Array.isArray(prices)) {
		console.error("Prices array is not defined or not an array");
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<Typography variant="h6" color="error">
					Unable to load pricing information.
				</Typography>
			</div>
		);
	}

	return (
		<Navbar name="Upgrade"
				sx={{
					alignItems: "center",
					justifyContent: "center",
					minHeight: "100vh",
				}}
		>
			<Box sx={{p: 2}}>
				<Box
					sx={{
						position: "relative",
						width: "100%",
						height: "710px",
						marginTop: "30px",
						paddingTop: "30px",
					}}
				>
					<Box
						sx={{
							padding: "20px",
							width: "100%",
							display: "flex",
						}}
					>
						{!isMobile ? (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								spacing={2}
							>
								<PricingCard
									tier={getTier(prices, 0)}
									width="400px"
									height="550px"
								/>
								<PricingCard
									tier={getTier(prices, 1)}
									width="400px"
									height="550px"
								/>
								<PricingCard
									tier={getTier(prices, 2)}
									width="400px"
									height="550px"
								/>
							</Grid>
						) : (
							<div>
								<ArrowButton
									dir="left"
									className="arrow-button"
									onClick={handlePrevious}
								>
									&lt;
								</ArrowButton>
								<PricingCard
									tier={getTier(prices, 0)}
									width="300px"
									height="550px"
								/>
								<ArrowButton
									dir="right"
									className="arrow-button"
									onClick={handleNext}
								>
									&gt;
								</ArrowButton>
							</div>
						)}
					</Box>
				</Box>
			</Box>
		</Navbar>
	);
}
