import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import Navbar from "./navbar.js";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {CustomersTable} from "../sections/customers-table.js";
import CustomersSearch from "../sections/customer-search.js";
import {ApiServiceClass} from "../components/api-service.js";

export default function Customers() {
	const [users, setUsers] = useState([]);
	const rawCompanyDetails = localStorage.getItem("companyData");
	const companyDetails = JSON.parse(rawCompanyDetails);
	const rawLoginData = localStorage.getItem("loginData");
	const loginData = JSON.parse(rawLoginData);
	let navigate = useNavigate();
	const [customers, setCustomers] = useState(null); // Initialize customers as null
	const [apiCallFinished, setApiCallFinished] = useState(false);
	const [totalCustomers, setTotalCustomers] = useState(0); // Initialize customers as null
	const [take, setTake] = useState(10); // Initialize customers as null
	const [skip, setSkip] = useState(0); // Initialize customers as null
	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	const [searchValue, setSearchValue] = useState("");

	const handleSearchChange = (value) => {
		setSearchValue(value); // Store the value of the text field in state
		console.log(value);
	};

	useEffect(() => {
		if (companyDetails === null || !loginData) {
			console.log("redirecting to /Login");
			navigate("/Login");
		}
		GetCustomers();
	}, []);

	useEffect(() => {
		// Fetch customers when customers state changes
	}, [customers, skip, take]); // Add customers to the dependency array

	const GetCustomers = async () => {
		try {
			var requestBody = {
				CompanyId: companyDetails.Id,
				Skip: 0,
				Take: take,
			};
			const data = await ApiServiceClass.GetCustomers(requestBody);
			//await delay(1000); // Wait for 2000 milliseconds (2 seconds)

			console.log("Get Customers Response", data);
			setCustomers(data.CustomerEntities);
			setTotalCustomers(data.TotalCustomerCount);
			console.log(customers);
			// Set apiCallFinished to true after all asynchronous operations
			setApiCallFinished(true);
		} catch (error) {
			// Handle error
			console.error("Error fetching data:", error);
		}
	};

	const handlePageChange = (newPage) => {
		console.log("Page changed to:", newPage);
		// Perform any actions you need when the page changes
		GetCustomers();
	};

	const handleChangeRowsPerPage = (newRowsPerPage) => {
		console.log("Rows per page changed to:", newRowsPerPage);
		setTake(newRowsPerPage);
		GetCustomers();
		// Perform any actions you need when the rows per page changes
	};

	const filteredCustomers =
		customers && customers.length > 0
			? customers.filter((customer) =>
				customer.FullName.toLowerCase().includes(searchValue.toLowerCase())
			)
			: [];

	const displayCustomers = searchValue
		? customers
			? filteredCustomers
			: []
		: customers;

	if (companyDetails == null) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<CircularProgress/>
			</div>
		);
	}

	return (
		<Navbar name="Customers">
			<div
				style={
					{
						// paddingLeft: "12px",
						// paddingTop: "20px"
					}
				}
			>
				<CustomersSearch
					onSearchChange={handleSearchChange}
					listCount={totalCustomers}
				/>
			</div>
			<Box
				backgroundColor="rewardspace.background"
				marginTop="10px"
				// paddingBottom="5px"
				// width="100%"
				style={{
					fontFamily: "Poppins-Medium",
					fontSize: "18px",
					color: "#000000",
				}}
			></Box>
			{!apiCallFinished ? (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100%", // Adjust the height as needed
					}}
				>
					<CircularProgress/>
				</div>
			) : (
				<CustomersTable
					items={displayCustomers}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
			<div style={{paddingBottom: "20px"}}></div>
		</Navbar>
	);
}
