import React, {useEffect, useState} from "react";
import {Image} from "lucide-react";

function BrandLogoUpload({initialImage, initialFileType, onImageChange}) {
	const [imageUrl, setImageUrl] = useState("");
	const [newFileName, setNewFileName] = useState("");
	const [newFileType, setNewFileType] = useState(initialFileType || ""); // Default to fileType passed in

	const fileTypeMapping = {
		"image/svg+xml": 1, // SVG MIME type -> 1
		"image/jpeg": 2, // JPEG MIME type -> 2
		"image/png": 3, // PNG MIME type -> 3
		"image/webp": 4, // WebP MIME type -> 4
	};

	const reverseFileTypeMapping = Object.fromEntries(
		Object.entries(fileTypeMapping).map(([key, value]) => [value, key])
	);

	useEffect(() => {
		if (initialImage) {
			const mimeType = reverseFileTypeMapping[initialFileType] || "image/png"; // Default to 'image/png' if not found
			const imageUrl = `data:${mimeType};base64,${initialImage}`; // Create preview URL for base64 image
			//console.log("image url: ", imageUrl);
			setImageUrl(imageUrl); // Set the image URL
		}
	}, []);

	const handleFileSelect = (event) => {
		const file = event.target.files[0];

		if (file && file.size <= 5 * 1024 * 1024) {
			// Check the file size (5MB limit)
			const allowedTypes = [
				"image/svg+xml",
				"image/jpeg",
				"image/png",
				"image/webp",
			]; // Allowed MIME types

			// Check if the file type is in the allowed types
			if (allowedTypes.includes(file.type)) {
				const reader = new FileReader();

				reader.onloadend = () => {
					const base64 = reader.result.split(",")[1]; // Extract the base64 content from the reader result
					const fileType = fileTypeMapping[file.type]; // Get the file's MIME type (e.g., image/jpeg, image/png)

					setImageUrl(reader.result); // Set the image URL
					setNewFileName(file.name); // Set the file name
					console.log("new image: ", base64); // Log the base64 string
					console.log("new image name: ", file.name); // Log the file name
					console.log("new image type raw: ", file.type);
					setNewFileType(fileType); // Set the file type
					console.log("new image type: ", fileType); // Log the file type
					onImageChange(base64, file.name, fileType); // Pass the base64, name, and type to the callback
				};

				reader.readAsDataURL(file); // Read the file as a Data URL
			} else {
				alert("Only SVG, JPEG, PNG, and WebP image formats are allowed.");
			}
		} else {
			alert("File size must be less than 5MB");
		}
	};

	const styles = getStyles();

	return (
		<div style={styles.container}>
			<div style={styles.uploadBox}>
				<div style={imageUrl ? styles.imageContainer : styles.iconContainer}>
					{imageUrl ? (
						<img
							src={imageUrl}
							alt="Uploaded logo"
							style={styles.previewImage}
						/>
					) : (
						<Image size={48} style={styles.icon}/>
					)}
				</div>
				{newFileName ? (
					<div style={styles.fileInfo}>Uploaded: {newFileName}</div>
				) : (
					<div style={styles.textContainer}>
						<p style={styles.mainText}>
							Upload company logo (.jpeg .png .svg .webp)
						</p>
						<p style={styles.subText}>Max file size 5MB</p>
					</div>
				)}

				<label style={styles.inputLabel}>
					<input
						type="file"
						style={styles.hiddenInput}
						accept=".jpeg,.png,.svg,.webp"
						onChange={handleFileSelect}
					/>
					<div style={styles.uploadButton}>Upload Logo</div>
				</label>
			</div>
		</div>
	);
}

const getStyles = () => ({
	container: {
		width: "100%",
		maxWidth: "500px",
		margin: "0 auto",
	},
	uploadBox: {
		borderRadius: "8px",
		padding: "10px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "16px",
	},
	iconContainer: {
		width: "100px",
		height: "100px",
		backgroundColor: "#f3f4f6",
		borderRadius: "8px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
	},
	imageContainer: {
		width: "300px",
		height: "300px",
		backgroundColor: "#f3f4f6",
		borderRadius: "8px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		objectFit: "fill",
	},
	icon: {
		color: "#9ca3af",
	},
	previewImage: {
		width: "100%",
		height: "auto", // Ensures the aspect ratio is maintained
		objectFit: "contain", // Ensures the image scales properly within the bounds
	},
	textContainer: {
		textAlign: "center",
	},
	mainText: {
		color: "#4b5563",
		marginBottom: "4px",
	},
	subText: {
		color: "#6b7280",
		fontSize: "14px",
	},
	inputLabel: {
		width: "100%",
		cursor: "pointer",
	},
	hiddenInput: {
		display: "none",
	},
	uploadButton: {
		display: "flex",
		margin: "0 auto",
		justifyContent: "center",
		alignItems: "center",
		width: "50%",
		padding: "10px 12px",
		backgroundColor: "#3464eb",
		color: "white",
		textAlign: "center",
		borderRadius: "6px",
		cursor: "pointer",
		transition: "background-color 0.2s ease",
	},
	fileInfo: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		fontSize: "14px",
		color: "#4b5563",
		textAlign: "center",
	},
});

export default BrandLogoUpload;
