import React, {useState} from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import {ApiServiceClass} from "../../api-service";
import {toast} from "react-toastify";

function SplashScreenContent({designManager}) {
	const [saveDisabled, setSaveDisabled] = useState(true);

	const [splashContent, setSplashContent] = useState({
		splashScreenBackgroundColor:
		designManager.getCurrentDesign().splashScreenBackgroundColor,
		splashScreenLogo: designManager.getCurrentDesign().splashScreenLogo,
		splashScreenLogoFileType:
		designManager.getCurrentDesign().splashScreenLogoFileType,
	});

	const handleColorChange = (contentProperty, newProperty) => {
		console.log(`[UNSAVED] Changed '${contentProperty}' to:`, newProperty);
		designManager.setUnsavedChanges({[contentProperty]: newProperty});

		setSplashContent((prevContent) => ({
			...prevContent,
			[contentProperty]: newProperty, // Dynamically update the property
		}));
		setSaveDisabled(false);
	};

	const handleImageChange = (newLogo, newFileName, newFileType) => {
		console.log("[UNSAVED] Changed splashScreenLogo to:", newLogo);
		console.log("[UNSAVED] Changed splashScreenLogoFileType to:", newFileType);
		designManager.setUnsavedChanges({["splashScreenLogo"]: newLogo});
		designManager.setUnsavedChanges({
			["splashScreenLogoFileType"]: newFileType,
		});

		setSplashContent((prevContent) => ({
			...prevContent,
			splashScreenLogo: newLogo, // Update with the new base64 image
			splashScreenLogoFileType: newFileType, // Update with the new file type (e.g., image/png, image/jpeg)
		}));
		setSaveDisabled(false);
	};

	const handleSave = async () => {
		try {
			if (!designManager.hasUnsavedChanges()) {
				console.log("No changes to save.");
				return;
			}
			const updatedDesign = designManager.getUnsavedDesign();
			const requestBody = {
				CompanyId: designManager.getCompanyId(),
				RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
				SplashLogo: updatedDesign.splashScreenLogo,
				SplashLogoFileType: updatedDesign.splashScreenLogoFileType,
				BackgroundColor: updatedDesign.splashScreenBackgroundColor,
				FromWeb: true,
			};
			console.log("Calling SaveSplashScreen API:", requestBody);
			const response = await ApiServiceClass.SaveSplashTheme(requestBody);
			if (response.status >= 400) {
				throw {
					status: response.status,
					message: `HTTP Error: ${response.status}`,
				};
			}
			designManager.updateDesign(updatedDesign);
			toast.success("Splash Theme Saved", {autoClose: 1500});
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Saving Splash Theme: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		} finally {
			setSaveDisabled(true);
		}
	};

	const handleReset = () => {
		console.log("Resetting design");
		designManager.resetUnsavedChanges();

		setSplashContent({
			splashScreenBackgroundColor:
			designManager.getCurrentDesign().splashScreenBackgroundColor,
			splashScreenLogo: designManager.getCurrentDesign().splashScreenLogo,
			splashScreenLogoFileType:
			designManager.getCurrentDesign().splashScreenLogoFileType,
		});
		setSaveDisabled(true);
	};

	return (
		<div style={Styles.panel}>
			<div style={Styles.contentWrapper}>
				<div style={Styles.sectionTitle}>Image</div>
				<div style={Styles.content}>
					<ImageBox
						initialImage={splashContent.splashScreenLogo}
						initialFileType={splashContent.splashScreenLogoFileType}
						onImageChange={(newImage, newFileName, newFileType) =>
							handleImageChange(newImage, newFileName, newFileType)
						}
					/>
				</div>
				<div style={Styles.sectionTitle}>Background</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Colour
						<ColourBox
							initColor={splashContent.splashScreenBackgroundColor}
							onColorChange={(newColor) =>
								handleColorChange("splashScreenBackgroundColor", newColor)
							}
						/>
					</div>
				</div>
			</div>
			<div style={Styles.actions}>
				<button style={Styles.resetBtn} onClick={handleReset}>
					Reset
					<Tooltip
						title={
							<span style={{display: "block", textAlign: "center"}}>
                This will reset all content to the last saved values
              </span>
						}
						arrow
					>
						<HelpOutlineIcon
							style={{
								marginLeft: "4px",
								fontSize: "17px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				</button>
				<button
					style={{
						...Styles.applyBtn,
						backgroundColor: saveDisabled ? "gray" : "#28a745",
						opacity: saveDisabled ? 0.5 : 1,
						cursor: saveDisabled ? "not-allowed" : "pointer",
					}}
					disabled={saveDisabled} // Only disable if no unsaved changes
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default SplashScreenContent;
