import Box from "@mui/material/Box";
import List from "@mui/material/List";
import MuiDrawer from "@mui/material/Drawer";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import DashNav from '../components/dashboard-nav';
import "../theme/styles/font.css";


const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({theme, open, drawerWidth}) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));


export const Navbar = ({name, title = "", children}) => {
	const [drawerWidth, setDrawerWidth] = useState(240);
	const [navOpen, setNavOpen] = useState(true);
	const rawLoginData = localStorage.getItem("loginData");
	const loginData = JSON.parse(rawLoginData);
	const rawCompanyDetails = localStorage.getItem("companyData");
	const companyDetails = JSON.parse(rawCompanyDetails);
	let navigate = useNavigate();

	const loginCheck = async () => {
		if (loginData === null) {
			navigate("/Login");
		}
	};

	const toggleDrawer = () => {
		setNavOpen((prev) => !prev);
		setDrawerWidth(prevWidth => prevWidth === 240 ? 55 : 240);
	};

	useEffect(() => {
		loginCheck();
	}, []);

	return (
		<Box component="main" sx={{display: "flex", width: "100%"}}>
			<link rel="stylesheet" href="../theme/styles/font.css" type="text/css"/>
			<Drawer
				sx={{
					background: "#FFFFFF",
					"& .MuiDrawer-paper": {
						borderRight: "1px solid #E3E6F1",
						backgroundColor: "#FFFFFF",
						minHeight: "100vh",
						// minHeight: "700px",
						overflow: "hidden",
					},
				}}
				drawerWidth={drawerWidth}
				variant="permanent"
				open={true}>
				<div
					style={{
						display: "flex",
						justifyContent: "start",
						alignItems: "start",
						marginTop: "0%",
						marginRight: "5%"
					}}>
					<List component="nav">
						<DashNav name={name} navOpen={navOpen} toggleDrawer={toggleDrawer}/>
					</List>
				</div>
			</Drawer>
			<Box //this box is the empty space at the bottom
				backgroundColor="rewardspace.background"
				display="flex"
				flexDirection="column"
				alignItems="stretch"
				style={{flex: 1, width: "100%"}}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="stretch"
					style={{
						flex: 1,
						width: "100%",
						overflow: "auto",
					}}
				>
					<Box
						backgroundColor="rewardspace.background"


						// width="98%"
						style={{
							fontFamily: "Poppins-Medium",
							fontSize: "18px",
							color: "#000000",
						}}
					>

					</Box>
					{/* content */}
					<Box paddingLeft="20px" paddingRight="20px" backgroundColor="rewardspace.background" width="100%"
						 height="100%">
						{children}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Navbar;
