import {useEffect, useState} from "react";
import Navbar from "./navbar.js";
import Typography from "@mui/material/Typography";
import {ApiServiceClass} from "../components/api-service.js";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import NewsSection from "../components/news-section.js";
import NewsContent from "../components/news-content"; // Import the NewsContent class
import NewCard from "../components/new-card.js"; // Import the NewsContent class
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AndroidIcon from "../tools/icons/android-store.png";
import AppleIcon from "../tools/icons/ios-store.png";
import MailIcon from "../tools/icons/envelope.png";
import InvoiceIcon from "../tools/icons/invoice.png";
import ProgressBar from "@ramonak/react-progress-bar";
import {SvgIcon} from "@mui/material";

export default function Dashboard() {
	const [dashboard, setDashboard] = useState([]);
	const [actions, setActions] = useState([]);
	let navigate = useNavigate();

	const rawCompanyDetails = localStorage.getItem("companyData");
	const companyDetails = JSON.parse(rawCompanyDetails);
	const rawLoginData = localStorage.getItem("loginData");
	const loginData = JSON.parse(rawLoginData);
	const [newsItems, setNewsItems] = useState([]); // Add state for news items

	useEffect(() => {
		const fetchData = async () => {
			if (companyDetails === null || loginData === null) {
				console.log("redirecting to /Login");
				navigate("/Login");
			}
			setNewsItems(NewsContent.getNewsItems()); // Load news items from NewsContent class

			await GetBasicDashboardInformation();
			await GetDashboardInformation();
		};

		fetchData();
	}, []);
	if (companyDetails == null) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<CircularProgress/>
			</div>
		);
	}

	const designIcon = actions.length === 0 || !actions.RewardSaved ? ErrorOutlineIcon : DoneIcon;
	const subscriptionIcon = actions.length === 0 || !actions.SubscriptionUpgraded ? ErrorOutlineIcon : DoneIcon;
	const publishedIcon = actions.length === 0 || actions.AndroidStatus === null || actions.AndroidStatus === 0 ? ErrorOutlineIcon : DoneIcon;
	const pushNotifIcon = actions.length === 0 || !actions.firstPushNotification ? ErrorOutlineIcon : DoneIcon;

	const GetBasicDashboardInformation = async () => {
		try {
			var requestBody = {
				CompanyId: companyDetails.Id,
				EmployeeId: loginData.UserId,
				RequesterUserId: loginData.UserId,
			};
			const basicDashboardInfo = await ApiServiceClass.GetBasicDashboardInformation(
				requestBody
			);
			setDashboard(basicDashboardInfo);
			console.log("Dashboard information response: ", basicDashboardInfo);

		} catch (error) {
			// Handle error
			console.error("Error fetching data:", error);
		}
	};

	const GetDashboardInformation = async () => {
		try {
			var requestBody = {
				CompanyId: companyDetails.Id,
			};
			const data = await ApiServiceClass.GetDashboardInformation(
				requestBody
			);
			setActions(data);
			console.log("Dashboard information (Actions) response: ", data);
		} catch (error) {
			// Handle error
			console.error("Error fetching data:", error);
		}
	};

	const MyTextComponent = ({value}) => {
		// Determine the color based on the range of values

		const colorThresholds = {
			red: -100,
			green: 100,
		};

		const calculateColor = (value) => {
			const normalizedValue = Math.min(
				1,
				Math.max(
					0,
					(value - colorThresholds.red) /
					(colorThresholds.green - colorThresholds.red)
				)
			);

			// Introduce a factor to determine how much black is mixed with the color
			const blackFactor = 0.5; // Adjust this value to control the amount of black

			// Calculate the red and green components with more black
			const red = Math.round(255 * (1 - normalizedValue) * (1 - blackFactor));
			const green = Math.round(255 * normalizedValue * (1 - blackFactor));

			const blue = 0;
			return `rgb(${red}, ${green}, ${blue})`;
		};

		const textColor = calculateColor(value);
		console.log("value colour " + value + "text colour " + textColor);
		return (
			<Typography>
				<span style={{marginRight: "4px", color: textColor}}>{value}%</span>
				vs the last 7 days{" "}
			</Typography>
		);
	};

	function getIOSStatusString(number) {
		const IOSStatusEnum = {
			0: "None",
			1: "Live",
			2: "Down",
			3: "Testing"
		};
		return IOSStatusEnum[number] || "Unknown Status";
	}

	const iosStatusString = getIOSStatusString(actions.IOSStatus)
	//console.log('actions.IOSStatus: ', actions.IOSStatus)
	//console.log('iosStatusString: ', iosStatusString)

	function getAndroidStatusString(number) {
		const AndroidStatusEnum = {
			0: "None",
			1: "Live",
			2: "Down",
			3: "Testing"
		};
		return AndroidStatusEnum[number] || "Unknown Status";
	}

	const androidStatusString = getAndroidStatusString(actions.AndroidStatus)
	//console.log('actions.AndroidStatus: ', actions.AndroidStatus)
	//console.log('androidStatusString: ', androidStatusString)

	function getSubscriptionString(number) {
		const SubscriptionEnum = {
			0: "None",
			1: "Lite",
			2: "Standard",
			3: "Professional"
		};
		return SubscriptionEnum[number] || "Unknown Status";
	}

	const subscriptionString = getSubscriptionString(actions.SubscriptionType)

	const listStyle = {
		padding: "15px",
		maxWidth: "48.5%",
		height: "auto",
		border: "0px solid #ddd",
		borderRadius: "12px",
		backgroundColor: "#ffffff",
		boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
	};

	const listItemStyle = {
		padding: "20px",

		display: "flex",
		alignItems: "center",
		borderBottom: "1px solid #ddd",
		textDecoration: "none",
		color: "inherit",
		transition: "background-color 0.3s",
	};

	const lastListItemStyle = {
		...listItemStyle,
		borderBottom: "none",
	};

	const textContentStyle = {
		display: "flex",
		flexDirection: "column",
		marginLeft: "10px",
	};

	const titleStyle = {
		margin: "0",
		fontSize: "14px",
		color: "#171663",
	};

	const descriptionStyle = {
		margin: "2px 0 0",
		fontSize: "14px",
		color: "#666",
	};

	const headerStyle = {
		fontSize: "16px",
		fontWeight: "bold",
		marginBottom: "10px",
		color: "#171663",
	};

	const ListItem = ({href, icon, title, description, isLast}) => (
		<a
			href={href}
			style={isLast ? lastListItemStyle : listItemStyle}
			onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
			onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")}
		>
			<SvgIcon
				component={icon}
				style={{
					color: icon === DoneIcon ? "green" : "orange",
					height: "30px",
					width: "30px",
					marginRight: "10px",
					verticalAlign: "middle",
				}}
			/>
			<div style={textContentStyle}>
				<h2 style={titleStyle}>{title}</h2>
				<p style={descriptionStyle}>{description}</p>
			</div>
		</a>
	);

	const lineStyle = {
		position: "sticky",
		width: "100%",
		borderRadius: "10px",
		height: "2px", // Adjust the height of the line as needed
		background: "#d9dbde",
	};

	const gridStyle = {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gap: "20px",
		padding: "20px",
	};

	const rightStyle = {
		display: "grid",
		gridTemplateRows: "repeat(2, 1fr)",
		gridTemplateColumns: "repeat(2, 1fr)",
		gap: "5px",
		padding: "10px",
	};

	const containerStyle = {
		display: "flex",
		marginTop: "20px",
	};

	const topStyle = {
		display: "flex",
		flexWrap: "nowrap",
		marginLeft: "-15px",
	};

	return (
		<Navbar name="Dashboard" title="Dashboard">
			<div style={{width: "95%", marginLeft: "2%", marginTop: "4%"}}>
				<div style={topStyle}>
					<NewCard
						title={`iOS Version: ${actions.IOSVersion}`}
						subtext={`Status: ${iosStatusString}`}
						iconSrc={AppleIcon}
						buttonText="Publish Center"
						buttonColour="#3464eb"
						imageHeight="42px"
						imageWidth="42px"
						buttonHref="/PublishCenter"
					/>
					<NewCard
						title={`Android Version: ${actions.AndroidVersion}`}
						subtext={`Status: ${androidStatusString}`}
						iconSrc={AndroidIcon}
						imageHeight="42px"
						imageWidth="42px"
						buttonText="Publish Center"
						buttonColour="#e6453e"
						buttonHref="/PublishCenter"
					/>
					<NewCard
						title="Email us"
						subtext="Got a question or suggestion?"
						iconSrc={MailIcon}
						buttonText="Get in touch"
						buttonColour="#21807e"
						imageHeight="46px"
						imageWidth="46px"
						buttonHref="/contact"
					/>
					<NewCard
						title="My Subscription"
						subtext={`Your Subscription: ${subscriptionString}`}
						iconSrc={InvoiceIcon}
						buttonText="Mange my subscription"
						buttonColour="#9f59cf"
						imageHeight="46px"
						imageWidth="46px"
						buttonHref="/upgrade"
					/>
				</div>
				<div style={containerStyle}>
					<div style={listStyle}>
						<div style={headerStyle}>Actions completed</div>
						<div style={{paddingBottom: "12px"}}>
							<ProgressBar
								completed={actions.ActionsPercent || 0}
								bgColor={"#2D62F9"}
								maxCompleted={100}
								height={18}
								labelSize={12}
							/>
						</div>
						<ListItem
							href="/design"
							icon={designIcon || ErrorOutlineIcon}
							title="Create your own design"
							description="Head to the design page and create your own mobile app. Download and view your custom app for free!"
						/>
						<ListItem
							href="/upgrade"
							icon={subscriptionIcon || ErrorOutlineIcon}
							title="Setup a subscription"
							description="Get started on one of our plans and grow your business."
						/>
						<ListItem
							href="/publishcenter"
							icon={publishedIcon || ErrorOutlineIcon}
							title="Publish your app"
							description="Get your app published to the iOS and Android store. Visit the publish centre for more information app store"
						/>
						<ListItem
							href="/statistics"
							icon={pushNotifIcon || ErrorOutlineIcon}
							title="Send a push notification"
							description="Send your first notification to your customers on the app."
							isLast={true}
						/>
					</div>
					<NewsSection newsItems={newsItems}/>
				</div>
			</div>
		</Navbar>
	);
}
