import React from "react";
import {ReactComponent as Icon} from "../resources/box_dialog.svg";
import battery from "../resources/ios-battery-full-3.svg";
import wifi from "../resources/ios-wifi-2.svg";

const OffersContent = ({designManager}) => {
	return (
		<div>
			<div style={{position: "relative"}}>
				<img
					src={wifi}
					alt="wifi"
					style={{
						width: "18px",
						height: "18px",
						position: "absolute",
						top: "-30px",
						right: "55px",
					}}
				/>
				<img
					src={battery}
					alt="battery"
					style={{
						width: "20px",
						height: "20px",
						position: "absolute",
						top: "-30px",
						right: "28px",
					}}
				/>
			</div>
			<div
				style={{
					width: "100%",
					draggable: "false",
					height: "40px",
					display: "flex", // Enable Flexbox
					alignItems: "center", // Vertically center
					justifyContent: "center", // Horizontally center
					backgroundColor: designManager.getFieldValue(
						"appHeaderBackgroundColor"
					),
					textAlign: "center",
					marginTop: "15px",
					color: designManager.getFieldValue("appHeaderFontColor"),
					fontSize: "14px",
					fontFamily: designManager.getFieldValue("appHeaderFont"),
					zIndex: 2,
				}}
			>
				Offers
			</div>
			<div
				style={{
					width: "100%",
					height: "500px",
					backgroundColor: designManager.getFieldValue(
						"primaryBackgroundColor"
					),
					color: designManager.getFieldValue("primaryFontColor"),
					fontSize: "14px",
					fontFamily: designManager.getFieldValue("primaryThemeFont"),
					display: "flex", // Flexbox for centering
					flexDirection: "column", // Arrange content vertically
					justifyContent: "center", // Center vertically
					alignItems: "center", // Center horizontally
				}}
			>
				<Icon
					style={{
						width: "120px",
						height: "120px",
						marginBottom: "16px",
						marginTop: "-30px",
						color: designManager.getFieldValue("primaryFontColor"), // This sets the color
					}}
				/>

				<div
					style={{
						fontSize: "14px",
						fontWeight: "bold",
						marginTop: "-20px",
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
					}}
				>
					No offers yet
				</div>
				<div
					style={{
						fontSize: "12px",
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
					}}
				>
					Check back later for new offers
				</div>
			</div>
		</div>
	);
};

export default OffersContent;
