import React, {useEffect, useState} from "react";
import {Box, Collapse, ListItemButton, ListItemText} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import logoSvg from "../tools/icons/logo.svg";
import ArrowLeft from "../tools/icons/arrow-left-stop.svg";
import ArrowRight from "../tools/icons/arrow-right-stop.svg";
import DashboardIcon from "../tools/icons/dashboard-icon.svg";
import EmployeeIcon from "../tools/icons/employee-icon.svg";
import CustomerIcon from "../tools/icons/customer-icon.svg";
import ReportsIcon from "../tools/icons/reports-icon.svg";
import DesignIcon from "../tools/icons/brand-icon.svg";
import SettingIcon from "../tools/icons/settings-icon.svg";
import UpgradeIcon from "../tools/icons/upgrade-icon.svg";
import HelpIcon from "../tools/icons/help-icon.svg";
import MarketIcon from "../tools/icons/satellite-dish.svg";
import ExtraIcon from "../tools/icons/crown.svg";
import RocketIcon from "../tools/icons/rocket.svg";
import VpnKeyIcon from "../tools/icons/voucher.svg";
import NotificationsIcon from "../tools/icons/bell.svg";
import SmsIcon from "../tools/icons/comments.svg";
import LogoutButton from "./logout-button";
import useIsMobile from "../components/mobile";

const DashNav = ({name, toggleDrawer, navOpen = true}) => {
	const [companyDetails, setCompanyDetails] = useState(null);
	const [marketingOpen, setMarketingOpen] = useState(false);
	const isMobile = useIsMobile();

	const handleMarketingClick = () => {
		setMarketingOpen(!marketingOpen);
	};

	const [menuItems, setMenuItems] = useState([
		{href: "/Dashboard", icon: DashboardIcon, label: "Dashboard"},
		{href: "/Employees", icon: EmployeeIcon, label: "Employees"},
		{href: "/Customers", icon: CustomerIcon, label: "Customers"},
		{href: "/Statistics", icon: ReportsIcon, label: "Statistics"},
		{
			href: "/PublishCenter",
			icon: RocketIcon,
			label: "Publish Center",
			visible: true,
		},
		{href: "/myapp", icon: DesignIcon, label: "My App"},
		{
			href: "#",
			icon: MarketIcon,
			label: "Marketing",
			submenu: [
				{href: "/Marketing/Vouchers", icon: VpnKeyIcon, label: "Vouchers"},
				{
					href: "/Marketing/PushNotifications",
					icon: NotificationsIcon,
					label: "Notifications",
				},
				{href: "/Marketing/SMS", icon: SmsIcon, label: "SMS"},
			],
		},
		{href: "/Help", icon: HelpIcon, label: "Help & Support"},
	]);

	useEffect(() => {
		const rawCompanyDetails = localStorage.getItem("companyData");
		const details = JSON.parse(rawCompanyDetails);
		setCompanyDetails(details);
	}, []);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				minHeight: "800px",
				height: "98vh",
			}}
		>
			<a href="/Dashboard" style={{textDecoration: "none", color: "inherit"}}>
				<Box
					sx={{
						padding: 0,
						width: "100%",
						marginLeft: "13px",
						position: "relative",
						marginTop: "3%",
						maxWidth: "100%",
						marginBottom: "0%",
						display: "flex",
						flexDirection: "row",
						alignItems: "center", // Center vertically
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32"
						height="32"
						style={{marginRight: "8px"}} // Adjusted margin between the SVG and text
					>
						<image href={logoSvg} width="32" height="32" alt="Logo"/>
					</svg>

					{navOpen ? (
						<span
							style={{
								fontFamily: "Poppins-SemiBold",
								fontSize: "22px",
								color: "#0260FE",
								whiteSpace: "nowrap",
							}}
						>
              Reward Space
            </span>
					) : null}
				</Box>
			</a>
			{isMobile ? (
				<a style={{textDecoration: "none", color: "inherit"}}>
					<Box
						sx={{
							padding: 0,
							width: "100%",
							marginLeft: "13px",
							position: "relative",
							marginTop: "10%",
							maxWidth: "100%",
							display: "flex",
							justifyContent: navOpen ? "flex-end" : "left",
							flexDirection: "row",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={toggleDrawer}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30"
							height="30"
							style={{marginRight: "8px"}}
						>
							{navOpen ? (
								<image
									href={ArrowLeft}
									width="30"
									height="30"
									alt="Close Nav"
								/>
							) : (
								<image
									href={ArrowRight}
									width="30"
									height="30"
									alt="Open Nav"
								/>
							)}
						</svg>
					</Box>
				</a>
			) : null}

			<Box sx={{flexGrow: 1, marginTop: "12%"}}>
				{menuItems
					.filter((item) => item.visible !== false)
					.map((item, index) => (
						<React.Fragment key={index}>
							<ListItemButton
								href={item.href}
								variant="outlined"
								onClick={item.submenu ? handleMarketingClick : undefined}
								sx={{
									background: name === item.label ? "#f6f6f6" : "initial",
								}}
							>
								<Box
									sx={{
										width: "100%",
										maxWidth: "100%",
										display: "flex",
										flexDirection: "row",
										alignItems: "center", // Center vertically
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										style={{display: "flex", alignItems: "center"}}
									>
										<image
											href={item.icon}
											width="20"
											height="20"
											alt={item.label}
										/>
									</svg>
									{navOpen ? (
										<ListItemText
											primary={item.label}
											primaryTypographyProps={{
												fontFamily: "Poppins-Medium",
												color: name === item.label ? "#0260FE" : "#171663",
												marginLeft: "20px",
												fontSize: "15.5px",
												alignItems: "center",
											}}
										/>
									) : null}

									{item.label === "Publish Center" && navOpen && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="18"
											height="18"
											style={{marginLeft: "15px"}}
										>
											<image
												href={ExtraIcon}
												width="18"
												height="18"
												alt="Extra Icon"
											/>
										</svg>
									)}

									{item.label === "Marketing" &&
										navOpen &&
										(marketingOpen ? <ExpandLess/> : <ExpandMore/>)}
								</Box>
							</ListItemButton>
							{item.submenu && (
								<Collapse in={marketingOpen} timeout="auto" unmountOnExit>
									{item.submenu.map((subitem, subindex) => (
										<ListItemButton
											key={subindex}
											href={subitem.href}
											sx={{
												pl: 4,
												paddingTop: 0, // Reduce top padding
												paddingBottom: 0, // Reduce bottom padding
												marginBottom: "5px", // Add margin between items
											}}
										>
											<Box
												sx={{
													width: "100%",
													maxWidth: "100%",
													display: "flex",
													flexDirection: "row",
													alignItems: "center", // Center vertically
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													style={{display: "flex", alignItems: "center"}}
												>
													<image
														href={subitem.icon}
														width="20"
														height="20"
														alt={subitem.label}
													/>
												</svg>
												<ListItemText
													primary={subitem.label}
													primaryTypographyProps={{
														fontFamily: "Poppins-Medium",
														color: "#4A47A3", // Set to grey
														marginLeft: "20px",
														fontSize: "14.5px", // One font size smaller
														alignItems: "center",
													}}
												/>
											</Box>
										</ListItemButton>
									))}
								</Collapse>
							)}
						</React.Fragment>
					))}
			</Box>

			<Box sx={{width: "100%"}}>
				{[
					{href: "/Upgrade", icon: UpgradeIcon, label: "Upgrade"},
					{href: "/Settings", icon: SettingIcon, label: "Settings"},
				].map((item, index) => (
					<ListItemButton key={index} href={item.href} variant="outlined">
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								alignItems: "center", // Center vertically
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								style={{display: "flex", alignItems: "center"}}
							>
								<image
									href={item.icon}
									width="20"
									height="20"
									alt={item.label}
								/>
							</svg>

							{true ? (
								<ListItemText
									primary={item.label}
									primaryTypographyProps={{
										fontFamily: "Poppins-Medium",
										color: name === item.label ? "#0260FE" : "#171663",
										marginLeft: "20px",
										fontSize: "15.5px",
										alignItems: "center",
									}}
								/>
							) : null}
						</Box>
					</ListItemButton>
				))}

				<LogoutButton navOpen={navOpen}/>
			</Box>
		</div>
	);
};

export default DashNav;
