import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import "./theme/index.css";
import reportWebVitals from "./reportWebVitals";
import {createTheme} from "./theme";
import {ThemeProvider} from "@mui/material/styles";
import {ToastContainer} from "react-toastify";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js"; // Import Elements
// Pages
import ErrorPage from "./pages/error-page";
import Home from "./pages/home";
import MyApp from "./dashboard/myapp";
import SubscriptionComplete from "./dashboard/subscription-complete";
import Checkout from "./dashboard/checkout";
import Login from "./pages/login";
import Upgrade from "./dashboard/upgrade";
import TermsAndConditions from "./pages/terms-and-conditions";
import Register from "./pages/register";
//import Design from "./dashboard/design";
import Employees from "./dashboard/employees";
import Customers from "./dashboard/customers";
import Pricing from "./pages/pricing";
import Features from "./pages/features";
import Dashboard from "./dashboard/dashboard";
import Rewards from "./dashboard/rewards";
import Help from "./dashboard/help";
import Settings from "./dashboard/settings";
import Statistics from "./dashboard/statistics";
import ContactUs from "./pages/contact-us";
import ResetPassword from "./pages/reset-password";
import PublishCenter from "./dashboard/publishing-center";
import EditPayment from "./dashboard/checkout-edit-payment";
import MarketingPushNotifications from "./dashboard/marketing-push-notifications"
import MarketingSMS from "./dashboard/marketing-sms"
import MarketingVouchers from "./dashboard/marketing-vouchers"


const stripePromise = loadStripe(
	"pk_test_51Oc7yxKYFd22hVPvVglZgEhzzn3OB7A5K8cPrsV051daN4dxG6rIkNdGI32quSINOJ280o0VXn7iLQ5hZm7tXhmu00kO3f7KHF"
);

const router = createBrowserRouter([
	// Home pages
	{path: "/", element: <Home/>, errorElement: <ErrorPage/>},
	{path: "/home", element: <Home/>, errorElement: <ErrorPage/>},
	{path: "/pricing", element: <Pricing/>, errorElement: <ErrorPage/>},
	{path: "/checkout", element: <Checkout/>, errorElement: <ErrorPage/>},
	{path: "/contact", element: <ContactUs/>, errorElement: <ErrorPage/>},
	{path: "/features", element: <Features/>, errorElement: <ErrorPage/>},
	// login / register
	{path: "/register", element: <Register/>, errorElement: <ErrorPage/>},
	{path: "/login", element: <Login/>, errorElement: <ErrorPage/>},
	{path: "/reset", element: <ResetPassword/>, errorElement: <ErrorPage/>},
	// Dashboard
	{path: "/dashboard", element: <Dashboard/>, errorElement: <ErrorPage/>},
	{path: "/rewards", element: <Rewards/>, errorElement: <ErrorPage/>},
	{path: "/publishcenter", element: <PublishCenter/>, errorElement: <ErrorPage/>,},
	{path: "/customers", element: <Customers/>, errorElement: <ErrorPage/>},
	{path: "/subscriptioncomplete", element: <SubscriptionComplete/>, errorElement: <ErrorPage/>,},
	{path: "/employees", element: <Employees/>, errorElement: <ErrorPage/>},
	{path: "/statistics", element: <Statistics/>, errorElement: <ErrorPage/>},
	{path: "/upgrade", element: <Upgrade/>, errorElement: <ErrorPage/>},
	//{ path: "/design", element: <Design />, errorElement: <ErrorPage /> },
	{path: "/marketing/pushnotifications", element: <MarketingPushNotifications/>, errorElement: <ErrorPage/>},
	{path: "/marketing/sms", element: <MarketingSMS/>, errorElement: <ErrorPage/>},
	{path: "/marketing/vouchers", element: <MarketingVouchers/>, errorElement: <ErrorPage/>},
	{path: "/myapp", element: <MyApp/>, errorElement: <ErrorPage/>},
	{path: "/settings", element: <Settings/>, errorElement: <ErrorPage/>},
	{path: "/help", element: <Help/>, errorElement: <ErrorPage/>},
	{
		path: "/editpayment",
		element: <EditPayment/>,
		errorElement: <ErrorPage/>,
	},

	// Other
	{
		path: "/terms",
		element: <TermsAndConditions/>,
		errorElement: <ErrorPage/>,
	},
]);

const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ThemeProvider theme={theme}>
		<Elements stripe={stripePromise}>
			<RouterProvider router={router}/>
			<ToastContainer/>
		</Elements>
	</ThemeProvider>
);

reportWebVitals();
