import React, {useCallback, useEffect} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {EmbeddedCheckout, EmbeddedCheckoutProvider,} from "@stripe/react-stripe-js";
import {useNavigate} from "react-router-dom";
import Navbar from "./navbar.js";
import CircularProgress from "@mui/material/CircularProgress";
import {ApiServiceClass} from "../components/api-service.js";

const stripePromise = loadStripe(
	"pk_test_51Oc7yxKYFd22hVPvVglZgEhzzn3OB7A5K8cPrsV051daN4dxG6rIkNdGI32quSINOJ280o0VXn7iLQ5hZm7tXhmu00kO3f7KHF"
);

const rawCompanyDetails = localStorage.getItem("companyData");
const companyDetails = JSON.parse(rawCompanyDetails);


const CreateCheckoutSession = async () => {
	try {
		const requestBody = {
			CustomerId: "cus_QMylUXbFzJZA8p",
			SubscriptionId: "sub_1PWEo9KYFd22hVPvdoKLnboI",
		};

		const data = await ApiServiceClass.EditPaymentDetails(requestBody);
		console.log("Checkout Session Data:", data);

		if (!data.ClientSecret) {
			throw new Error("FAIL 2: Missing clientSecret in response data");
		}

		return data;
	} catch (error) {
		console.error("FAIL 4", error);
		throw error;
	}
};

const CheckoutForm = () => {
	const fetchClientSecret = useCallback(async () => {
		try {
			const sessionData = await CreateCheckoutSession();
			return sessionData.ClientSecret;
		} catch (error) {
			console.error("Error fetching client secret:", error);
			throw error; // Rethrow the error to be caught by the caller
		}
	}, []);

	const options = {fetchClientSecret};

	return (
		<div id="checkout" style={{width: "70%"}}>
			<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
				<EmbeddedCheckout/>
			</EmbeddedCheckoutProvider>
		</div>
	);
};

const EditPayment = () => {
	const rawCompanyDetails = localStorage.getItem("companyData");
	const companyDetails = JSON.parse(rawCompanyDetails);
	const rawLoginData = localStorage.getItem("loginData");
	const loginData = JSON.parse(rawLoginData);
	const navigate = useNavigate();

	useEffect(() => {
		if (!companyDetails || !loginData) {
			navigate("/Login");
		}
	}, [companyDetails, navigate]);

	if (!companyDetails) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<CircularProgress/>
			</div>
		);
	}

	return (
		<Navbar
			sx={{
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
			}}
		>
			<CheckoutForm/>
		</Navbar>
	);
};

export default EditPayment;
